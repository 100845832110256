import React from 'react'
import PropTypes from 'prop-types'
import Masonry from 'react-masonry-component'

import SEO from '../SEO'
import { SimpleHeader } from '../Header'
import SingleProject from './SingleProject'
// import useGroupColumns from '../../hooks/useGroupColumns'

import styles from './All.module.css'

const AllProjects = ({ location, projects }) => {
  return (
    <>
      <SEO title="All Projects" />

      <SimpleHeader />

      <main className={styles.container}>
        <div className={styles.space}>
          <Masonry
            options={{
              columnWidth: `.${styles.projectContainer}`,
              itemSelector: `.${styles.projectContainer}`,
              percentPosition: true,
            }}
          >
            {projects.map(project => {
              return (
                <div
                  key={project.id}
                  className={[
                    styles.projectContainer,
                    styles.columnPadding,
                  ].join(' ')}
                >
                  <SingleProject project={project} location={location} />
                </div>
              )
            })}
          </Masonry>
        </div>

        {/* <div className={[styles.space, 'desktop-only'].join(' ')}>
          {columnProjects.map(column => (
            <div className={styles.column}>
              {column.map(columnProject => (
                <div className={styles.columnPadding}>
                  <SingleProject
                    isMobile={isMobile}
                    project={columnProject}
                    location={location}
                  />
                </div>
              ))}
            </div>
          ))}
        </div> */}
      </main>
    </>
  )
}

AllProjects.propTypes = {
  projects: PropTypes.arrayOf({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,

    categories: PropTypes.arrayOf({
      Name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AllProjects
