import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import ResponsiveVideo from '../ResponsiveVideo'

import styles from './All.module.css'

export default function SingleProject({
  project,
  location,
  className,
  ...props
}) {
  const featuredVimeo = project.featured_vimeo

  return (
    <div className={[styles.project, className].join(' ')} {...props}>
      {featuredVimeo ? (
        <>
          <Link className={styles.videoLink} to={`/${project.slug}`} />
          <ResponsiveVideo
            vimeoId={featuredVimeo.vimeo_url}
            width={featuredVimeo.width}
            height={featuredVimeo.height}
            className={styles.video}
          />
        </>
      ) : (
        <Link to={`/${project.slug}`}>
          <Img
            className={styles.image}
            fluid={project.featured_image.childImageSharp.fluid}
          />
        </Link>
      )}

      <h2 className={styles.title}>{project.name}</h2>

      <ul className={styles.categories}>
        {project.categories.map(category => (
          <li key={category.id}>{category.Name}</li>
        ))}
      </ul>
    </div>
  )
}
