import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import All from '../components/All'

const AllProjects = ({ location, data }) => {
  const {
    allStrapiProject: { nodes: projects },
  } = data

  return <All projects={projects} location={location} />
}

AllProjects.propTypes = {
  data: PropTypes.shape({
    allStrapiProject: PropTypes.array,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default AllProjects

export const query = graphql`
  {
    allStrapiProject(
      filter: { draft: { ne: true } }
      sort: { order: DESC, fields: order }
    ) {
      nodes {
        slug
        name
        categories {
          Name
          id
        }
        featured_vimeo {
          vimeo_url
          width
          height
        }
        featured_image {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
